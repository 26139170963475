import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CustomAuthGuard } from './guards/custom-auth.guard';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadComponent: () =>
      import('./homepage/homepage.component').then(
        (mod) => mod.HomepageComponent
      ),
  },
  {
    path: 'faq',
    loadComponent: () =>
      import('./faq/faq.component').then((mod) => mod.FaqComponent),
  },

  {
    path: 'home-sales-refinancing',
    loadComponent: () =>
      import('./home-sales-refinancing/home-sales-refinancing.component').then(
        (mod) => mod.HomeSalesRefinancingComponent
      ),
    canActivate: [CustomAuthGuard],
  },

  {
    path: 'battery-solutions',
    loadComponent: () =>
      import('./battery-solutions/battery-solutions.component').then(
        (mod) => mod.BatterySolutionsComponent
      ),
  },

  {
    path: 'forms',
    loadComponent: () =>
      import('./forms/forms.component').then((mod) => mod.FormsComponent),
    canActivate: [CustomAuthGuard],
  },
  {
    path: 'privacy-policy',
    loadComponent: () =>
      import('./privacy-policy/privacy-policy.component').then(
        (mod) => mod.PrivacyPolicyComponent
      ),
  },
  {
    path: 'monitoring',
    loadComponent: () =>
      import('./monitoring/monitoring.component').then(
        (mod) => mod.MonitoringComponent
      ),
    canActivate: [CustomAuthGuard],
  },
  {
    path: 'dashboard',
    loadComponent: () =>
      import('./dashboard/dashboard.component').then(
        (mod) => mod.DashboardComponent
      ),
    canActivate: [CustomAuthGuard],
  },
  {
    path: 'careers',
    loadComponent: () =>
      import('./careers/careers.component').then((mod) => mod.CareersComponent),
  },
  {
    path: 'licenses',
    loadComponent: () =>
      import('./licenses/licenses.component').then(
        (mod) => mod.LicensesComponent
      ),
  },
  {
    path: 'get-in-touch',
    loadComponent: () =>
      import('./contact-us/contact-us.component').then(
        (mod) => mod.ContactUsComponent
      ),
  },
  {
    path: 'service-charges',
    loadComponent: () =>
      import('./service-charges/service-charges.component').then(
        (mod) => mod.ServiceChargesComponent
      ),
  },
  {
    path: 'environmental-commodities-markets',
    loadComponent: () =>
      import(
        './environmental-commodities-markets/environmental-commodities-markets.component'
      ).then((mod) => mod.EnvironmentalCommoditiesMarketsComponent),
  },
  {
    path: 'fleet-management',
    loadComponent: () =>
      import('./fleet-management/fleet-management.component').then(
        (mod) => mod.FleetManagementComponent
      ),
  },
  {
    path: 'about',
    loadComponent: () =>
      import('./about/about.component').then((mod) => mod.AboutComponent),
  },
  {
    path: 'pro',
    loadComponent: () =>
      import('./pro/pro.component').then((mod) => mod.ProComponent),
  },
  {
    path: 'spruce-servicing',
    loadComponent: () =>
      import('./spruce-servicing/spruce-servicing.component').then(
        (mod) => mod.SpruceServicingComponent
      ),
  },
  {
    path: 'page-not-found',
    loadComponent: () =>
      import('./page-not-found/page-not-found.component').then(
        (mod) => mod.PageNotFoundComponent
      ),
  },

  {
    path: 'portal-faq',
    loadComponent: () =>
      import('./portal-faq/portal-faq.component').then(
        (mod) => mod.PortalFaqComponent
      ),
  },

  {
    path: 'user-settings',
    loadComponent: () =>
      import('./user-settings/user-settings.component').then(
        (mod) => mod.UserSettingsComponent
      ),
    canActivate: [CustomAuthGuard],
  },
  {
    path: 'life-events',
    loadComponent: () =>
      import('./life-events/life-events.component').then(
        (mod) => mod.LifeEventsComponent
      ),
  },

  {
    path: 'life-events/refinance',
    loadComponent: () =>
      import('./life-events-refinance/life-events-refinance.component').then(
        (mod) => mod.LifeEventsRefinanceComponent
      ),
  },

  {
    path: 'life-events/form/solar-agreement-amendment-request',
    loadComponent: () =>
      import('./life-events/forms/solar-agreement-amendment-request/solar-agreement-amendment-request.component').then(
        (mod) => mod.SolarAgreementAmendmentRequestComponent
      ),
  },

  {
    path: 'life-events/form/buyout-and-prepayment-request',
    loadComponent: () =>
      import('./life-events/forms/buyout-and-prepayment-request/buyout-and-prepayment-request.component').then(
        (mod) => mod.BuyoutAndPrepaymentRequestComponent
      ),
  },

  {
    path: 'life-events/form/refinance-information-request',
    loadComponent: () =>
      import('./life-events/forms/refinance-information-request/refinance-information-request.component').then(
        (mod) => mod.RefinanceInformationRequestComponent
      ),
  },

  {
    path: 'life-events/form/home-sale-information/escrow-facilitated-transactions-request',
    loadComponent: () =>
      import('./life-events/forms/home-sale-information/escrow-facilitated-transactions-request/escrow-facilitated-transactions-request.component').then(
        (mod) => mod.EscrowFacilitatedTransactionsRequestComponent
      ),
  },

  {
    path: 'life-events/form/home-sale-information/lawyer-facilitated-transactions-request',
    loadComponent: () =>
      import('./life-events/forms/home-sale-information/lawyer-facilitated-transactions-request/lawyer-facilitated-transactions-request.component').then(
        (mod) => mod.LawyerFacilitatedTransactionsRequestComponent
      ),
  },

  {
    path: 'life-events/form/success-message',
    loadComponent: () =>
      import('./life-events/forms/success-message/success-message.component').then(
        (mod) => mod.SuccessMessageComponent
      ),
  },

  {
    path: '**',
    loadComponent: () =>
      import('./page-not-found/page-not-found.component').then(
        (mod) => mod.PageNotFoundComponent
      ),
  },
];
